import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { map as _map, find as _find } from 'lodash';
import { graphql } from 'gatsby';

import Layout from "@components/layout"
import SEO from "@components/seo"
import { Hero } from "@molecules/hero"
import Section from '@atoms/section';
import Box from '@molecules/box';

import './identify-your-needs.scss';


const defaultBoxesData = [
  {
    name: 'basic-signs',
    title: `iyn:box1Title`,
    subtitle: 'iyn:box1Subtitle',
    buttonText: 'iyn:box1ButtonText',
    buttonUrl: {
        en: '/chapters/chapter-1',
        fr: '/chapitres/chapitre-1',
    }
  },
  {
    name: 'question',
    title: 'iyn:box2Title',
    subtitle: 'iyn:box2Subtitle',
    buttonText: 'iyn:box2ButtonText',
    buttonUrl: '/questionnaire'
  },
];

const IdentifyYourNeedsPage = ({ data: queryData }) => {
  const { t, i18n } = useTranslation();
  const boxes = _map(queryData?.allFile?.edges, edge => {
      const node = edge?.node;
      const boxData = _find(defaultBoxesData, box => box.name === node.name);

      const buttonUrl = boxData.buttonUrl.en ? boxData.buttonUrl[i18n.language] : boxData.buttonUrl;

      return {
        ...boxData,
          buttonUrl,
        ...node,
      }
  });

  return (
    <Layout>
      <SEO title={t('iyn:title')} />
      <Hero
        title="iyn:heroTitle"
        description="iyn:heroDescription"
        info="iyn:heroInfo" />
      <Section className="mt-5">
        <div className="row">
          {
            _map(boxes, (box, key) => {
              const { name, title, subtitle, buttonText, buttonUrl, description, publicURL: icon } = box;
              return (
                <div className={`col-md-12 col-lg-5 box--${name}`} key={key}>
                  <Box
                    subtitle={subtitle}
                    title={title}
                    description={description}
                    buttonText={buttonText}
                    buttonUrl={buttonUrl}
                    icon={icon}
                  >
                  </Box>
                </div>
              )
            })
          }
        </div>
      </Section>
    </Layout>
  )
};

export const query = graphql`
  query{
    allFile(filter: {name: {in: ["question", "basic-signs"]}, relativeDirectory: {eq: "icons"}}) {
        edges {
            node {
                name
                publicURL
            }
        }
    }
  }
`;

export default IdentifyYourNeedsPage;
