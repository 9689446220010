import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'gatsby-plugin-react-i18next';
import classNames from 'classnames';
import { Button, BUTTON_TYPE_GRADIENT } from '@atoms/button';
import './box.scss';

const COMPONENT_CLASS = 'box';

const Box = ({ icon, title, subtitle, description, buttonUrl, buttonText, border, stretchedLink }) => {

    const borderClass = border ? `${COMPONENT_CLASS}--border` : null;
    const classes = classNames(COMPONENT_CLASS, borderClass, {});
    const components = {
        strong: <strong />,
        u: <u />,
        i: <i />,
        p: <p />,
        br: <br />,
        span: <span />,
    };

    return (
        <article className={classes}>
            { icon ?
                (
                    <div className={`${COMPONENT_CLASS}__icon-container`}>
                        <div className={`${COMPONENT_CLASS}__icon`}>
                            <img src={icon} alt={title} className={`${COMPONENT_CLASS}__icon-img`}/>
                        </div>
                    </div>
                )
                : null}
            { subtitle ?
                (
                    <div className={`${COMPONENT_CLASS}__number small`}>
                        <Trans components={components}>{subtitle}</Trans>
                    </div>
                )
                : null
            }
            <h3 className={`${COMPONENT_CLASS}__title`}>
                {
                 buttonText ?
                    (
                        <Trans components={components}>{title}</Trans>
                    )
                :
                    (
                        <Button
                            to={buttonUrl}
                            type={BUTTON_TYPE_GRADIENT}
                            stretchedLink={stretchedLink}
                            >
                            {title}
                        </Button>
                    )
                }
            </h3>
            { description ?
                (
                    <p className={`${COMPONENT_CLASS}__description`}>
                        <Trans components={components}>{description}</Trans>
                    </p>
                )
                : null
            }
            { buttonText && buttonUrl?
                <div className={`${COMPONENT_CLASS}__button`}><Button to={buttonUrl}>{buttonText}</Button></div>
                : null
            }
        </article>
    );
};

Box.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    buttonUrl: PropTypes.string,
    buttonText: PropTypes.string,
    border: PropTypes.bool,
    stretchedLink: PropTypes.bool,
};

Box.defaultProps = {
    icon: null,
    subtitle: null,
    description: null,
    buttonUrl: '#',
    buttonText: null,
    border: true,
    stretchedLink: false,
};

export default Box;
